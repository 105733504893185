<template>
  <div>
    <b-row>
      <b-col cols="12" v-if="loading">
        <b-card align="center">
          <b-spinner
            style="width: 3rem; height: 3rem"
            class="mr-1"
            label="Large Spinner"
          />
        </b-card>
      </b-col>
      <b-col cols="12" v-show="!loading">
        <vimeo-player
          ref="player"
          :video-id="$route.params.id"
          class="p-1"
          :options="{
            responsive: true,
          }"
          :autoplay="true"
          :controls="false"
          :player-width="640"
          :player-height="320"
          @ready="onReady"
          allowfullscreen
          @ended='ended'
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <b-card align="center">
          <b-button
            v-if="playing"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="btn-icon"
            size="lg"
            @click="pause"
          >
            <feather-icon icon="PauseIcon" />
          </b-button>
          <b-button
            v-else
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="btn-icon"
            size="lg"
            @click="play"
          >
            <feather-icon icon="PlayIcon" />
          </b-button>
          <b-button
            v-if="!muted"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="danger"
            class="btn-icon ml-2"
            size="lg"
            @click="mute"
          >
            <feather-icon icon="VolumeXIcon" />
          </b-button>
          <b-button
            v-else
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="btn-icon ml-2"
            size="lg"
            @click="unmute"
          >
            <feather-icon icon="Volume2Icon" />
          </b-button>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import vueVimeoPlayer from "vue-vimeo-player";
import { BCard, BRow, BCol, BButton, BModal, BSpinner } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BModal,
    BSpinner,
    vueVimeoPlayer,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      videoId: "",
      playing: true,
      loading: true,
      muted: false,
    };
  },
  methods: {
    pause() {
      this.$refs.player.pause();
      this.playing = false;
    },
    play() {
      this.$refs.player.play();
      this.playing = true;
    },
    onReady() {
      this.$refs.player.play();

      this.loading = false;
      this.playerReady = true;
    },
    mute() {
      this.$refs.player.mute();
      this.muted = true
    },
    unmute() {
        
      this.$refs.player.unmute();
      this.muted = false
    },
    ended() {
        console.log('cabooo')
    }
  },
};
</script>

<style lang="scss">
</style> 